import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:分页查询入库单记录表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16857
 */
export function receiptOrderRecordPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order_record/page', data, {
    params,
  });
}

/**
 * @description:导出入库单记录
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16859
 */
export function receiptOrderRecordExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/receipt_order_record/export', data, { fileName: '入库明细记录', extension: 'xlsx' });
}
