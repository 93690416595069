import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';

const DICTIONARY = {
};
// 入库类型
const RECEIPT_TYPE = [{
  value: 'ORDER',
  label: '订单入库',
}, {
  value: 'RETURNED',
  label: '客退入库',
}, {
  value: 'REJECTED',
  label: '拒收入库',
}, {
  value: 'TRANSFER',
  label: '调拨入库',
}, {
  value: 'SETUP',
  label: '期初建账',
}, {
  value: 'VIRTUAL',
  label: '虚拟入库',
}, {
  value: 'THROUGH',
  label: '经由品入库',
}, {
  value: 'OTHER',
  label: '其它入库',
}];
const SOURCE = [{
  value: 'OMS',
  label: 'OMS',
}, {
  value: 'WMS',
  label: 'WMS',
}];
// 温层
const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};
export {
  DICTIONARY,
  RECEIPT_TYPE,
  SOURCE,
  TEMPERATURE_LAYER,
};

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

export const TABLECOLUMN = [{
  type: 'selection',
  width: 55,
}, {
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '入库时间',
  prop: 'bookingTime',
  ...DateTime,
}, {
  label: '到货登记时间',
  prop: 'arrivalTime',
  ...DateTime,
}, {
  label: '入库单号',
  prop: 'receiptOrderNo',
  minWidth: 160,
}, {
  label: '货主订单号',
  prop: 'customerOrderNo',
  minWidth: 160,
}, {
  label: '货主名称',
  prop: 'shipperName',
  minWidth: 120,
}, {
  label: '入库单类型',
  prop: 'receiptType',
  minWidth: 100,
  formatter: (row) => filterLabel(row.receiptType, RECEIPT_TYPE),
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 120,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '供应商',
  prop: 'supplierName',
  minWidth: 120,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  minWidth: 80,
}, {
  label: '入库数量(默认单位)',
  prop: 'defaultUnitAmount',
  minWidth: 160,
}, {
  label: '入库重量(KG)',
  prop: 'totalWeight',
  minWidth: 120,
}, {
  label: '入库体积(m³)',
  prop: 'totalVolume',
  minWidth: 120,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  minWidth: 80,
  formatter: (row) => TEMPERATURE_LAYER[row.temperatureLayer],

}, {
  label: '入库板数',
  prop: 'veneer',
  minWidth: 100,
}, {
  label: '批次',
  prop: 'batchNo',
  minWidth: 180,
}, {
  label: '生产日期',
  prop: 'productDate',
  ...DateTime,
}, {
  label: '到期日期',
  prop: 'expireDate',
  ...DateTime,
},
{
  label: '柜号',
  prop: 'boxNo',
  minWidth: 100,
}];

export const serchFields = [
  {
    label: '入库单号',
    prop: 'receiptOrderNo',
    component: FormType.INPUT,
  }, {
    label: '入库单类型',
    prop: 'receiptType',
    component: FormType.SELECT,
    options: RECEIPT_TYPE,
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '货主订单号',
    prop: 'customerOrderNo',
    component: FormType.INPUT,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    component: FormType.INPUT,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    component: FormType.INPUT,
  }, {
    label: '创建时间',
    prop: 'creatTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
    },
  }, {
    label: '入库时间',
    prop: 'bookingTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
    },
  },
  {
    label: '到货登记时间',
    prop: 'arrivalTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
    },
  },
];
