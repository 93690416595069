<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields"
        @submit="handleQuery"
        @reset="handleQuery"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="searchQuery.shipperId"
          />
        </template>
      </PlForm>
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getTableData"
        :data="tableData"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @selection-change="handleSelectionChange"
      >
        <template #tableHeaderRight>
          <nh-button
            :track="{
              trackName: '报表中心/入库明细/[导出]'
            }"
            @click="handleExport"
          >
            导出
          </nh-button>
        </template>
      </PlTable>
    </pl-block>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from '@/utils/moment';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import { receiptOrderRecordPage, receiptOrderRecordExport } from './api';

export default {
  name: 'InWarehouseDetails',
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      searchQuery: {
        receiptOrderNo: null,
        receiptType: null,
        customerOrderNo: null,
        shipperGoodsCode: null,
        goodsName: null,
        creatTime: [],
        bookingTime: [moment.startOf(new Date(), 'month'), moment.ensOf(new Date(), 'month')],
        arrivalTime: [],
        shipperId: null,

      },
      selectIds: [],
      loading: {
        getTableData: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.tableData = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await receiptOrderRecordPage(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    buildParams() {
      const [beforeCreateTime, afterCreateTime] = this.searchQuery.creatTime || [];
      const [beforeBookingTime, afterBookingTime] = this.searchQuery.bookingTime || [];
      const [beforeArrivalTime, afterArrivalTime] = this.searchQuery.arrivalTime || [];
      return {
        ...this.searchQuery,
        beforeCreateTime,
        afterCreateTime,
        beforeBookingTime,
        afterBookingTime,
        beforeArrivalTime,
        afterArrivalTime,
      };
    },
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
    },
    async handleExport() {
      const data = this.buildParams();
      if (this.selectIds.length > 0) {
        data.ids = this.selectIds;
      }
      receiptOrderRecordExport(data);
    },
  },
};
</script>
